//
//
//
//
//
//
//
//
//
//

import { Hooper, Pagination as HooperPagination, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  components: {
    Hooper,
    HooperPagination,
    Slide
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    setTimeout(() => this.$refs.hooper.updateWidth());
  }
};
