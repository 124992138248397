// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/montserrat-v21-latin-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/montserrat-v21-latin-regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/montserrat-v21-latin-500.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/montserrat-v21-latin-500.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/fonts/montserrat-v21-latin-600.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/fonts/montserrat-v21-latin-600.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../assets/fonts/montserrat-v21-latin-700.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../../assets/fonts/montserrat-v21-latin-700.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../../assets/fonts/montserrat-v21-latin-800.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../../assets/fonts/montserrat-v21-latin-800.woff");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../../assets/fonts/montserrat-v21-latin-900.woff2");
var ___CSS_LOADER_URL_IMPORT_11___ = require("../../assets/fonts/montserrat-v21-latin-900.woff");
var ___CSS_LOADER_URL_IMPORT_12___ = require("../../assets/fonts/montserrat-v21-latin-italic.woff2");
var ___CSS_LOADER_URL_IMPORT_13___ = require("../../assets/fonts/montserrat-v21-latin-italic.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Montserrat\";font-style:normal;font-weight:400;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-family:\"Montserrat\";font-style:normal;font-weight:500;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}@font-face{font-family:\"Montserrat\";font-style:normal;font-weight:600;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}@font-face{font-family:\"Montserrat\";font-style:normal;font-weight:700;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\")}@font-face{font-family:\"Montserrat\";font-style:normal;font-weight:800;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"woff\")}@font-face{font-family:\"Montserrat\";font-style:normal;font-weight:900;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"woff\")}@font-face{font-family:\"Montserrat\";font-style:italic;font-weight:400;src:local(\"\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format(\"woff\")}.hooper[data-v-6ad27fb6]{height:unset}img[data-v-6ad27fb6]{width:100%;height:100%;-o-object-fit:contain;object-fit:contain}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
