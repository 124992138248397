//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import home from '~/middleware/home';
import getContactInfo from '~/queries/contactInfo';
import getHomePrivate from '~/queries/homePrivate';
import getHomePublic from '~/queries/homePublic';

export default {
  name: 'IndexPage',
  nuxtI18n: {
    paths: {
      'en-ca': '/home',
      'fr-ca': '/accueil'
    }
  },
  head() {
    return {
      title: this.$t('home.metaTitle')
    };
  },
  async asyncData({ app, i18n, $auth }) {
    const client = app.apolloProvider.defaultClient;
    const locale = i18n.locale.slice(0, 3) + i18n.locale.slice(3).toUpperCase();
    const contactResponse = await client.query({
      query: getContactInfo,
      variables: { locale: locale }
    });

    const contactInfo = contactResponse.data.informationsDeContact.data.attributes;

    const homePublicResponse = await client.query({
      query: getHomePublic,
      variables: { locale: locale }
    });

    const homePublic = homePublicResponse.data.accueilModeNonConnecte.data.attributes;

    let homePrivate;

    if ($auth.loggedIn) {
      const homePrivateResponse = await client.query({
        query: getHomePrivate,
        variables: { locale: locale },
        context: {
          headers: {
            Authorization: $auth.getToken('local')
          }
        }
      });

      homePrivate = homePrivateResponse.data.accueilModeConnecte.data.attributes;
    }

    return { contactInfo, homePrivate, homePublic };
  },
  async created() {},
  methods: {},
  computed: {
    images() {
      if (!this.homePublic?.welcomeBanner?.images) {
        return [];
      }

      return this.homePublic.welcomeBanner.images.data.map(x => x.attributes.url);
    },
    isLoggedIn() {
      return this.user.name !== null;
    },
    user() {
      return {
        name: this.$auth.$state?.user?.username ?? null
      };
    }
  },
  watch: {
    isLoggedIn(value, old) {
      if (!old && value) {
        this.$nuxt.refresh();
      }
    }
  }
};
